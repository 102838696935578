import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import AnimatedBackground from '../../common/AnimatedBackground';
import Circle from '../../../images/product/processing/circle.no-inline.svg';
import CircleTransparent from '../../../images/product/processing/circle-transparent.no-inline.svg';
import Triangle from '../../../images/product/processing/triangle.no-inline.svg';
import TriangleTransparent from '../../../images/product/processing/triangle-transparent.no-inline.svg';
import Square from '../../../images/product/processing/square.no-inline.svg';
import SquareTransparent from '../../../images/product/processing/square-transparent.no-inline.svg';
import {ScreenSizeContext} from '../../../helpers/context';
import {colors} from '../../../styles/theme';


/*
 * Helper methods
 */
/**
 * Get the shape width we need to use based on the screen size
 *
 * @param {number} screenWidth - the width of the screen in pixels
 * @returns {number} - the width of the shape to use
 */
const getShapeWidth = screenWidth => {
    let shapeWidth = 0;

    if (screenWidth < 576) {
        shapeWidth = 45;
    } else if (screenWidth < 768) {
        shapeWidth = 55;
    } else if (screenWidth < 1600) {
        shapeWidth = 65;
    } else {
        shapeWidth = 85;
    }

    return shapeWidth;
};


/*
 * Private Elements
 */
const StyledDrawableBackground = styled.div`
    .animated-background {
        position: relative;
    }
`;

const StyledHoverablePrimitiveShape = styled.span`
    .opaque {
        display: block;
    }

    .transparent {
        display: none;
    }

    &.active {
        .opaque {
            display: none;
        }

        .transparent {
            display: block;
        }
    }
`;

/**
 * Draw a shape that can be hovered to show the underlying background
 *
 * @param {number} width - the width of the shape
 * @param {string} opaqueImage - the url of the opaque image to use
 * @param {string} transparentImage - the url of the transparent image (when hovered)
 * @param {string} shape - the shape of the hoverable area
 * @param {string} coordinates - the coordinates of the hoverable area
 */
const HoverablePrimitiveShape = ({width, opaqueImage, transparentImage, shape, coordinates}) => {
    const {current: mapName} = useRef(Math.random().toString(36).substring(2, 15));
    const [active, setActive] = useState(false);

    // Clear all shapes on resize, to prevent them from being active behind text
    useEffect(() => {
        setActive(false);
    }, [width]);

    // TODO: Do not allow activation if element is behind stuff
    return (
        <StyledHoverablePrimitiveShape className={active ? 'active' : ''} style={{width: `${width}px`, height: 'auto'}}>
            <img src={opaqueImage} alt="" useMap={`#${mapName}`} className="opaque" />
            <img src={transparentImage} alt="" useMap={`#${mapName}`} className="transparent" />

            <map name={mapName}>
                <area
                    shape={shape}
                    coords={coordinates}
                    alt=""
                    onMouseEnter={() => setActive(!active)}
                />
            </map>
        </StyledHoverablePrimitiveShape>
    );
};

HoverablePrimitiveShape.propTypes = {
    width: PropTypes.number.isRequired,
    opaqueImage: PropTypes.string.isRequired,
    transparentImage: PropTypes.string.isRequired,
    shape: PropTypes.string.isRequired,
    coordinates: PropTypes.string.isRequired,
};

const HoverableCircle = ({width}) => (
    <HoverablePrimitiveShape
        width={width}
        opaqueImage={Circle}
        transparentImage={CircleTransparent}
        shape="circle"
        coordinates={`${width / 2}, ${width / 2}, ${width / 2}`}
    />
);

HoverableCircle.propTypes = {
    width: PropTypes.number.isRequired,
};

const HoverableTriangle = ({width}) => (
    <HoverablePrimitiveShape
        width={width}
        opaqueImage={Triangle}
        transparentImage={TriangleTransparent}
        shape="poly"
        coordinates={`0, ${width / 2}, ${width / 2}, 0, ${width}, ${width / 2}`}
    />
);

HoverableTriangle.propTypes = {
    width: PropTypes.number.isRequired,
};

const HoverableSquare = ({width}) => (
    <HoverablePrimitiveShape
        width={width}
        opaqueImage={Square}
        transparentImage={SquareTransparent}
        shape="rect"
        coordinates={`0, 0, ${width}, ${width}`}
    />
);

HoverableSquare.propTypes = {
    width: PropTypes.number.isRequired,
};

const Filler = styled.div`
    width: 100%;
    background-color: ${colors.white};
`;

const StyledHoverableShape = styled.span`
    display: flex;
    flex-direction: column;
    flex: 1 1;
    height: auto;

    // Prevent selecting the shapes with the mouse
    -webkit-touch-callout: none;
    user-select: none;

    ${StyledHoverablePrimitiveShape} {
        width: 100%;
        height: auto;

        img {
            width: 100%;
            height: auto;
        }
    }
`;

/**
 * Draw a hoverable shape, taking its position in consideration
 *
 * @param {boolean} width - the width of the shape
 * @param {boolean} firstRow - whether the shape is in the first row or not
 * @param {boolean} oddColumn - whether the shape is in an odd column or not
 */
const HoverableShape = ({width, firstRow = false, oddColumn = false}) => {
    // If it is in the first row, and it in an odd column, we only draw the bottom square and add a space filler on top
    // of it.
    if (firstRow && oddColumn) {
        return (
            <StyledHoverableShape>
                <Filler style={{height: `${width / 2}px`}} />
                <HoverableSquare width={width} />
            </StyledHoverableShape>
        );
    }

    // If it is in an odd column, but not on the first row, we draw a regular shape that is placed higher than the other
    // (even) shapes, to make skewed.
    if (oddColumn) {
        return (
            <StyledHoverableShape style={{marginTop: `-${width}px`}}>
                <HoverableTriangle width={width} />
                <HoverableCircle width={width} />
                <HoverableSquare width={width} />
            </StyledHoverableShape>
        );
    }

    // Otherwise, just render the basic shape
    return (
        <StyledHoverableShape>
            <HoverableTriangle width={width} />
            <HoverableCircle width={width} />
            <HoverableSquare width={width} />
        </StyledHoverableShape>
    );
};

HoverableShape.propTypes = {
    width: PropTypes.number.isRequired,
    firstRow: PropTypes.bool,
    oddColumn: PropTypes.bool,
};

HoverableShape.defaultProps = {
    firstRow: false,
    oddColumn: false,
};

const StyledHoverableShapesBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .row {
        flex-wrap: nowrap;
    }
`;

/**
 * A background filled with hoverable shapes that fills its parent
 */
const HoverableShapesBackground = () => {
    const screenSize = useContext(ScreenSizeContext);
    const backgroundRef = useRef();
    const [shapes, setShapes] = useState([]);

    // Depending on screen size, calculate the number of shapes per row (which is necessary to calculate the number of
    // rows)
    useEffect(() => {
        // Do not spend time on the first render where the screen width is still not set
        if (screenSize.width === 0) {
            return;
        }

        // Calculate the shapes' measurements
        const shapeWidth = getShapeWidth(screenSize.width);
        const shapesPerRow = Math.ceil(screenSize.width / shapeWidth);

        // A shape has 2.5x its width as height, since it is composed by 2 squared elements (the square and circle), and
        // a rectangular one (the top triangle, which has half its width as height). The only exception is first row
        // shapes on odd columns, but it doesn't matter here since what we want is to calculate the number of stacked
        // shapes necessary to fill the container.
        const shapeHeight = Math.ceil(shapeWidth * 2.5);

        // Always insert an extra row to compensate for the odd ones that end before the even
        const numberOfRows = Math.ceil(backgroundRef.current.scrollHeight / shapeHeight) + 1;

        const rows = [];

        // Iterate all the rows and columns to add the correct shapes to the array.
        for (let i = 0; i < numberOfRows; i++) {
            const firstRow = i === 0;
            const row = [];

            for (let k = 0; k < shapesPerRow; k++) {
                const oddColumn = k % 2 === 1;
                row.push(
                    <HoverableShape
                        width={shapeWidth}
                        firstRow={firstRow}
                        oddColumn={oddColumn}
                        key={`shape-${i}-${k}`}
                    />,
                );
            }

            rows.push(<div className="row no-gutters" key={`row-${i}`}>{row}</div>);
        }

        // Set the shapes so that they are rendered
        setShapes(rows);
    }, [screenSize.width]);

    return (
        <StyledHoverableShapesBackground ref={backgroundRef}>
            {shapes}
        </StyledHoverableShapesBackground>
    );
};


/*
 * Public Elements
 */
const DrawableBackground = ({children}) => (
    <StyledDrawableBackground>
        <AnimatedBackground>
            <HoverableShapesBackground />

            {children}
        </AnimatedBackground>
    </StyledDrawableBackground>
);

DrawableBackground.propTypes = {
    children: PropTypes.node.isRequired,
};


/*
 * Exports
 */
export {
    DrawableBackground,
    getShapeWidth,
};
