import React, {useEffect, useRef} from 'react';


/**
 * A wrapper for Javascript's setInterval (with the same exact signature) that wraps the existing imperative API into
 * React's declarative API.
 * It handles changing either the handler or timeout graciously, as well as pausing the counter by setting the timeout
 * to null.
 *
 * Props to https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param {TimerHandler} handler - function to run in the specified interval
 * @param {number || null} timeout - the interval to use, or null for pausing
 */
export default (handler, timeout) => {
    const savedCallback = useRef();

    // Remember the latest callback
    useEffect(() => {
        savedCallback.current = handler;
    }, [handler]);

    // Set up the interval
    useEffect(() => {
        const tick = () => savedCallback.current();
        if (timeout !== null) {
            const id = setInterval(tick, timeout);
            return () => clearInterval(id);
        }

        return undefined;
    }, [timeout]);
};
