import React, {useContext, useState} from 'react';
import {StaticImage} from 'gatsby-plugin-image';

import styled from '@emotion/styled';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import {ContrastContext, ScreenSizeContext} from '../../helpers/context';
import SEO from '../../components/common/SEO';
import ProductHero from '../../components/common/ProductHero';
import ProductPageContainer from '../../components/common/ProductPageContainer';
import {breakpoints, colors, ContentContainer, fontWeights} from '../../styles/theme';
import {H1, H5, H6, Hero} from '../../components/common/Typography';
import {CTA, LinkAsSecondaryButton} from '../../components/common/Clickables';
import {DrawableBackground, getShapeWidth} from '../../components/product/processing/DrawableBackground';
import useInterval from '../../helpers/hooks/useInterval';
import AnimatedBackground from '../../components/common/AnimatedBackground';


/*
 * Constants
 */
/* eslint-disable-next-line i18next/no-literal-string */
const videoUrl = null;

const paymentChannelsKeywords = [
    <Trans>custom</Trans>,
    <Trans>private label</Trans>,
    <Trans>credit-to-consumer</Trans>,
    <Trans>loyalty</Trans>,
    <Trans>own</Trans>,
];


/*
 * Private Elements
 */
const FinancialInstitutionsContainer = styled.div`
    ${ContentContainer} {
        padding-top: 70px;

        ${H1} {
            margin-bottom: 25px;
        }

        ${H6} {
            font-weight: ${fontWeights.regular};
            margin-bottom: 50px;
        }

        .financial-institutions-image {
            margin-top: auto;
            padding-right: 50px;
            padding-left: 50px;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        background: linear-gradient(
            110deg,
            ${colors.black},
            ${colors.darkPurple},
            ${colors.purple},
            ${colors.darkerPurple}
        );

        ${ContentContainer} {
            ${H1} {
                margin-top: 70px;
            }
        }
    }
`;

const ReachCustomersContainer = styled.div`
    color: ${colors.white};
    background-color: ${colors.black};
    padding-top: 75px;
    padding-bottom: 30px;

    ${ContentContainer} {
        text-align: center;

        .content {
            margin: auto;
            max-width: 650px;

            ${H1} {
                margin-bottom: 25px;
            }

            ${H6} {
                margin-bottom: 50px;
            }

            ${LinkAsSecondaryButton} {
                margin-bottom: 35px;
            }
        }
    }

    .image {
        width: 100%;
        max-width: 1300px;
        padding-left: 25px;
        padding-right: 25px;
        margin: auto;
    }

    @media not all and (min-width: ${breakpoints.md}) {
        ${ContentContainer} {
            .content {
                ${H6} {
                    font-weight: ${fontWeights.regular};
                }
            }
        }
    }
`;

const CustomPaymentChannelsContainer = styled.div`
    ${ContentContainer} {
        padding-top: 70px;
        padding-bottom: 150px;

        .row {
            position: relative;
            align-items: center;

            .hero {
                min-height: 300px;

                ${Hero} {
                    display: inline;

                    .custom {
                        display: block;
                        background: linear-gradient(to bottom, ${colors.darkPurple}, ${colors.black});
                        background-clip: text;
                        text-fill-color: transparent;
                    }
                }
            }

            .content {
                ${H5} {
                    font-weight: ${fontWeights.regular};
                    margin-bottom: 45px;
                }
            }

            // Underlay to prevent the drawable component of drawing on shapes behind the text
            .underlay {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: ${colors.transparent};
            }
        }
    }

    @media (min-width: ${breakpoints.sm}) {
        ${ContentContainer} {
            .row {
                text-align: center;

                .hero {
                    min-height: 200px;
                }
            }
        }
    }

    @media (min-width: ${breakpoints.md}) {
        ${ContentContainer} {
            padding-top: 150px;

            .row {
                text-align: initial;

                .hero {
                    margin-left: 1%;
                    height: initial;

                    ${Hero} {
                        height: 100%;
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }

                .content {
                    margin-left: auto;

                    ${H5} {
                        margin-top: 65px;
                        margin-bottom: 65px;
                    }
                }
            }
        }
    }

    @media (min-width: ${breakpoints.xl}) {
        ${ContentContainer} {
            .row {
                .content {
                    ${H5} {
                        margin-top: 85px;
                        margin-bottom: 85px;
                    }
                }
            }
        }
    }
`;

const DevelopmentContainer = styled.div`
    position: relative;
    padding-top: 10px;
    padding-bottom: 120px;
    background: ${colors.transparent} linear-gradient(to top, ${colors.ghostWhite}, rgba(255, 255, 255, 0));

    ${ContentContainer} {
        ${H1} {
            margin-bottom: 25px;
        }

        ${H6} {
            font-weight: ${fontWeights.regular};
            margin-bottom: 50px;
        }

        .image {
            margin-bottom: 40px;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        padding-top: 25px;

        ${ContentContainer} {
            .image {
                margin-bottom: 0;
            }
        }
    }
`;

const CustomPaymentChannelKeyword = () => {
    const [keywordIndex, setKeywordIndex] = useState(0);

    // Keep changing the keyword index
    useInterval(() => {
        const idx = (keywordIndex + 1) % paymentChannelsKeywords.length;
        setKeywordIndex(idx);
    }, 2500);

    return <span className="custom">{paymentChannelsKeywords[keywordIndex]}</span>;
};


/*
 * Public Elements
 */
const ProcessingPage = () => {
    const setContrast = useContext(ContrastContext);
    setContrast(false);
    const {t} = useTranslation();

    const section = t('Processing');
    // eslint-disable-next-line i18next/no-literal-string
    const sectionStr = 'Processing';
    const hero = t('Every payment flow, one integration.');
    const description = t('The Switch API uses the same abstractions for all transactions, regardless of them being '
        + 'synchronous, payins, payouts or marketplaces, redirection-based, pre-payments or post-payments, one-time or '
        + 'recurring. Scale your payments operation without any further development efforts.');
    const image = (
        <StaticImage src="../../images/product/processing/logo.png" placeholder="none" loading="eager" alt="" />
    );
    const mobileImage = (
        <StaticImage
            src="../../images/product/processing/logo-mobile.png"
            placeholder="none"
            loading="eager"
            alt=""
        />
    );

    // Allow animation on the margins of the PaymentChannelsContainer
    const screenSize = useContext(ScreenSizeContext);
    const shapeSize = getShapeWidth(screenSize.width);
    let contentContainerStyle = {};
    let paymentChannelRowStyle = {};

    // For smaller screens we will not have animation on the margins
    if (screenSize.width < parseInt(breakpoints.sm.replace('px', ''), 10)) {
        contentContainerStyle = {
            // eslint-disable-next-line i18next/no-literal-string
            'padding-left': '0px',
            // eslint-disable-next-line i18next/no-literal-string
            'padding-right': '0px',
        };

        paymentChannelRowStyle = {
            // eslint-disable-next-line i18next/no-literal-string
            'padding-left': '25px',
            // eslint-disable-next-line i18next/no-literal-string
            'padding-right': '25px',
        };
    } else {
        paymentChannelRowStyle = {
            'margin-left': `${shapeSize}px`,
            'padding-right': `${shapeSize}px`,
            'margin-right': `${shapeSize}px`,
        };
    }

    return (
        <>
            <SEO
                title={section}
                ogTitle={t('One Integration for Multiple Payment Providers - Switch')}
                description={t('Gain instant access to any payment provider on a low code basis. Integrate the payment '
                    + 'methods that best fit your customers through a single platform.')}
                image="/website/metatags/processing.png"
            />

            <ProductHero
                section={section}
                sectionStr={sectionStr}
                hero={hero}
                description={description}
                image={image}
                mobileImage={mobileImage}
                videoUrl={videoUrl}
            />

            <ProductPageContainer>
                <FinancialInstitutionsContainer>
                    <AnimatedBackground contrast>
                        <ContentContainer>
                            <div className="row no-gutters">
                                <div className="col-12 col-md-5 order-md-2">
                                    <H1>
                                        <Trans>
                                            Achieve redundancy and negotiation power through the access to multiple
                                            financial institutions.
                                        </Trans>
                                    </H1>
                                    <H6>
                                        <Trans>
                                            By integrating with Switch you gain instant access to any financial
                                            institution without additional technological investment. This allows you to
                                            negotiate competitive contracts with all payment stakeholders.
                                        </Trans>
                                    </H6>
                                </div>
                                <div className="col-12 col-md-6 financial-institutions-image order-md-1">
                                    <StaticImage
                                        src="../../images/product/processing/financial-institutions.png"
                                        placeholder="blurred"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </ContentContainer>
                    </AnimatedBackground>
                </FinancialInstitutionsContainer>

                <ReachCustomersContainer className="product-shadow">
                    <ContentContainer>
                        <div className="content">
                            <H1><Trans>Reach your customers locally.</Trans></H1>

                            <H6>
                                <Trans>
                                    Access 50+ local payment methods and local payment processors to provide customers a
                                    more convenient shopping experience while keeping a personalized checkout process.
                                </Trans>
                            </H6>

                            <LinkAsSecondaryButton to="/channels">
                                <Trans>See our channels</Trans>
                            </LinkAsSecondaryButton>
                        </div>
                    </ContentContainer>

                    <div className="image">
                        <AnimatedBackground contrast>
                            <StaticImage
                                src="../../images/product/processing/map.png"
                                placeholder="blurred"
                                alt=""
                            />
                        </AnimatedBackground>
                    </div>
                </ReachCustomersContainer>

                <DrawableBackground>
                    <CustomPaymentChannelsContainer>
                        <ContentContainer style={contentContainerStyle}>
                            <div className="row no-gutters" style={paymentChannelRowStyle}>
                                <div className="underlay" />

                                <div className="col-12 col-md-6 hero">
                                    <Hero>
                                        <Trans>
                                            Create your
                                            {' '}
                                            <CustomPaymentChannelKeyword />
                                            {' '}
                                            payment channels.
                                        </Trans>
                                    </Hero>
                                </div>
                                <div className="col-12 col-md-5 content">
                                    <H5>
                                        <Trans>
                                            Build convenient payment channels with custom authentication parameters.
                                            Switch&apos;s architecture supports financial transactions like
                                            credit-to-consumer and private label schemes and also virtual value schemes
                                            like loyalty and coupons.
                                        </Trans>
                                    </H5>
                                </div>
                            </div>
                        </ContentContainer>
                    </CustomPaymentChannelsContainer>

                    <DevelopmentContainer>
                        <ContentContainer>
                            <div className="row no-gutters">
                                <div className="col-12 col-md-4 offset-md-2 image">
                                    <StaticImage
                                        src="../../images/product/processing/development-dependency.png"
                                        placeholder="blurred"
                                        alt=""
                                    />
                                </div>
                                <div className="col-12 col-md-4 offset-md-1">
                                    <H1><Trans>Waiting on development? Forget about it.</Trans></H1>

                                    <H6>
                                        <Trans>
                                            With Dynamic Forms, you can incorporate new payment methods and providers
                                            without any changes to the code. Accelerate your go-to-market strategy.
                                        </Trans>
                                    </H6>

                                    <CTA to="https://switchpayments.com/docs/dynamic-forms" colored>
                                        <Trans>Learn more about Dynamic Forms</Trans>
                                    </CTA>
                                </div>
                            </div>
                        </ContentContainer>
                    </DevelopmentContainer>
                </DrawableBackground>
            </ProductPageContainer>
        </>
    );
};


/*
 * Exports
 */
export default ProcessingPage;
